import React from 'react'
import eero from '../assets/images/EERO-BUNDLE.png'
import wideCoverage from '../assets/images/wide-coverage.png'
import secure from '../assets/images/secure.png'
import fastSpeeds from '../assets/images/fast-speeds.png'
import highPerformance from '../assets/images/high-performance.png'

const EeroFeature = () => (
  <div className="container">
    <section className="plan-details">
      <div className="row mb-5">
        <div className="col-md-3">
          <div className="eero-img">
            <img src={wideCoverage} alt="icon earth" />
            <h3>Wide Coverage</h3>
          </div>
        </div>

        <div className="col-md-3">
          <div className="eero-img">
            <img src={fastSpeeds} alt="icon speed" />
            <h3>Fast Speeds</h3>
          </div>
        </div>

        <div className="col-md-3">
          <div className="eero-img">
            <img src={highPerformance} alt="icon performance" />
            <h3>High Performance</h3>
          </div>
        </div>

        <div className="col-md-3">
          <div className="eero-img">
            <img src={secure} alt="icon lock" />
            <h3>eero Secure</h3>
          </div>
        </div>
      </div>

      <div className="row eero-modem">
        <div className="col-md-8">
          <p>
            Wi-Fi 6 is the largest generation of Wi-Fi technology. It supports
            fast speeds and up to 75 connected devices. With a high throughput,
            you can seamlessly roam your home without drop-offs and maintain top
            speeds even while on congested networks. It can even improve the
            battery life of devices connected to your network. With Wi-Fi 6,
            you're getting the best in Wi-Fi technology.
          </p>
        </div>
        <div className="col-md-4">
          <div className="eero-img">
            <img src={eero} alt="eero 6" width="200" height="150" />
          </div>
        </div>
      </div>
      <div className="row eero-description">
        <div className="col-md-6">
          <h5>
            <strong>Entertainment-ready Wi-Fi</strong>
          </h5>
          <p>
            Enjoy premium-quality content, stream music, play online video games
            confidently, and stay connected when on a video chat.
          </p>
        </div>
        <div className="col-md-6">
          <h5>
            <strong>Low lag so you can game fast</strong>
          </h5>
          <p>
            Nothing kills online gaming like lagging. Since eeros dynamically
            route traffic, you can game confidently from the moment you hit go
            until you log off.
          </p>
        </div>
      </div>
      <div className="row eero-description">
        <div className="col-md-6">
          <h5>
            <strong>Wi-Fi security made simple</strong>
          </h5>
          <p>
            eero Secure protects you by helping to keep your personal
            information, devices, and network protected from online threats with
            world-class security.
          </p>
        </div>
        <div className="col-md-6">
          <h5>
            <strong>Every room, every device</strong>
          </h5>
          <p>
            Stop pacing around the house looking for the Wi-Fi, eero devices
            work together to optimize Wi-Fi coverage throughout your whole home.
          </p>
        </div>
      </div>
    </section>
  </div>
)

export default EeroFeature
