import React from 'react'
import Layout from '../components/Layout'
import EeroFeature from '../components/EeroFeature'

const TomiEeroPage = () => {
  return (
    <Layout>
      <main id="page-tomi-eero">
        <section class="banner">
          <img src="/img/eero-header.png"></img>
        </section>
        <section className="plans">
          <div className="container">
            <div className="tomi-eero-container">
              <h1 className="eero-page-title">Tomi + eero 6</h1>
              <h3 className="eero-subtitle">Speed. Reliability. Security.</h3>
              <p>
                eero supports fast connectivity to every room for more devices
              </p>
              <p style={{ fontSize: `13px` }}>
                while helping to keep your Wi-Fi safe and secure.
              </p>
            </div>
          </div>

          <EeroFeature />
        </section>
      </main>
    </Layout>
  )
}

export default TomiEeroPage
